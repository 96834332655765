<template>
  <div id="contents" class="modi usage_status">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="openTabIndex"
          :commit="'setOpenTabIndexToUserActionPage'"
          :options="[
            { title: '사용자 이용현황', name: 'user_action' },
            { title: '메뉴 이용현황', name: 'menu_action' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            :class="index == openTabIndex ? 'active' : ''"
            v-for="(subTab, index) in subTabs"
            :key="subTab.name"
          >
            <a @click="setOpenTabIndex(index)">{{ subTab.title }}</a>
          </li>
        </ul>
      </div>
      <!--  -->
      <!-- <user-action-form v-if="openTabIndex == 0"></user-action-form> -->
      <component :is="dynamicComponent" v-if="openTabIndex == 0"> </component>
      <component :is="dynamicComponent2" v-if="openTabIndex == 1"> </component>
      <!-- <menu-action-form v-if="openTabIndex == 1"></menu-action-form> -->
    </div>
  </div>
</template>

<script>
// import MenuActionForm from '@/views/forms/System/MenuActionForm.vue';
// import UserActionForm from '@/views/forms/System/UserActionForm.vue';
import ClosePageMixin from '@/mixins/closePage';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import FavoriteMixin from '@/mixins/favorite';

import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    // UserActionForm,
    // MenuActionForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      subTabs: 'getSubTabsFromUserActionPage',
      openTabIndex: 'getOpenTabIndexFromUserActionPage',
      user_actions: 'getUserAction',
      start_date: 'getStartDateFromUserActionPage',
    }),
    dynamicComponent() {
      if (Number(localStorage.getItem('saupKey')) == 8)
        return () => import('@/views/forms/System/8/WoonsukUserActionForm.vue');
      else return () => import('@/views/forms/System/UserActionForm.vue');
    },
    dynamicComponent2() {
      if (Number(localStorage.getItem('saupKey')) == 17) {
        return () => import('@/views/forms/System/17/CNSMenuActionForm.vue');
      } else if (Number(localStorage.getItem('saupKey')) == 18) {
        return () => import('@/views/forms/System/18/BomnaeMenuActionForm.vue');
      } else if (Number(localStorage.getItem('saupKey')) == 20) {
        return () => import('@/views/forms/System/20/OsungMenuActionForm.vue');
      } else if (Number(localStorage.getItem('saupKey')) == 21) {
        return () => import('@/views/forms/System/21/DaejinMenuActionForm.vue');
      } else if (Number(localStorage.getItem('saupKey')) == 22) {
        return () => import('@/views/forms/System/22/TteokMenuActionForm.vue');
      } else return () => import('@/views/forms/System/MenuActionForm.vue');
    },
  },
  methods: {
    ...mapMutations({
      setOpenTabIndex: 'setOpenTabIndexToUserActionPage',
      resetDate: 'resetDateToUserActionPage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitUserActionPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
